import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { AUTH_TOKEN } from "redux/constants/Auth";
import i18next from "i18next";
import ExampleService from "services/ExampleService";
import axios from "axios";

const LoadApp = () => {
  const dispatch = useDispatch();
  const getLangInfo = async () => {
    try {
      let getLangId = localStorage.getItem("languageId");
      if (!getLangId) {
        getLangId ="81ab7132-45a3-486e-ade1-dc15fe9f8210"
        localStorage.setItem("languageId", getLangId);
        window.location.reload();
      }
      const responseLang = await axios.post(
        "https://api.varonline.app/language/dropdown"
      );
      let defaultItems = null;
      if (getLangId)
        defaultItems = responseLang?.data?.data.filter(
          (item) => item?.id == getLangId
        );
      else
        defaultItems = responseLang?.data?.data.filter(
          (item) => item?.isDefault
        );
      const responseDataTranslate = await axios.post(
        "https://api.varonline.app/dataTranslate/dropdown/" +
          defaultItems[0]?.id
      );
      var list = responseDataTranslate?.data?.data?.map((item) => {
        return `"${item.keyword}" : "${(item.value + "").replace(
          /"/g,
          '\\"'
        )}"`;
      });
      var translateData = JSON.parse(
        '{"' +
          defaultItems[0]?.shortName +
          '"' +
          ': {"translation": {' +
          list.join(",") +
          "}}} "
      );
      i18next.init(
        {
          lng: defaultItems[0]?.shortName,
          debug: false,
          resources: translateData,
          keySeparator: true,
        },
        (error) => {
          console.log(error);
        }
      );
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getLangInfo();
    const jwtToken = localStorage.getItem(AUTH_TOKEN);
    if (jwtToken) {
      ExampleService.getPost("auth/userinfo", null)
        .then((resp) => {
          localStorage.setItem("userinfo", JSON.stringify(resp.data?.user));
        })
        .catch((e) => {
          if (e) console.log(e);
        });
    } else {
      if (window.location?.pathname != "/auth/login") {
        window.location.href = "/auth/login";
      }
    }
  }, []);

  return null;
};

export default LoadApp;
