import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH } from 'configs/AppConfig'

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route path={`${APP_PREFIX_PATH}/home`} component={lazy(() => import(`./home`))} />
        <Route path={`${APP_PREFIX_PATH}/users`} component={lazy(() => import(`./users`))} />
        <Route path={`${APP_PREFIX_PATH}/customers`} component={lazy(() => import(`./customers`))} />
        <Route path={`${APP_PREFIX_PATH}/logs`} component={lazy(() => import(`./logs`))} />
        <Route path={`${APP_PREFIX_PATH}/matches`} component={lazy(() => import(`./matches`))} />
        <Route path={`${APP_PREFIX_PATH}/varTeams`} component={lazy(() => import(`./varTeams`))} />
        <Route path={`${APP_PREFIX_PATH}/varReferee`} component={lazy(() => import(`./varReferee`))} />
        <Route path={`${APP_PREFIX_PATH}/match/:id`} component={lazy(() => import(`./matches/detail`))} />
        <Route path={`${APP_PREFIX_PATH}/contact`} component={lazy(() => import(`./contact`))} />
        <Route path={`${APP_PREFIX_PATH}/announcements`} component={lazy(() => import(`./announcements`))} />
        <Route path={`${APP_PREFIX_PATH}/language`} component={lazy(() => import(`./language`))} />
        <Route path={`${APP_PREFIX_PATH}/customer/:id`} component={lazy(() => import(`./customers/detail`))} />
        <Route path={`${APP_PREFIX_PATH}/translate`} component={lazy(() => import(`./translate`))} />
        <Route path={`${APP_PREFIX_PATH}/settings`} component={lazy(() => import(`./settings`))} />
        <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/home`} />
      </Switch>
    </Suspense>
  )
}

export default React.memo(AppViews);